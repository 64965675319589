import request from '@/utils/request'

export type LocationProp = {
  text: string
  value: string
  children?: LocationProp[]
}[]

export type LocationItem = {
  provinceCode?: string
  provinceName?: string
  cityCode?: string
  cityName?: string
  areaCode?: string
  areaName?: string
  text?: string
  value?: string
  children: LocationItem[]
}
export type LocationResponse = Array<LocationItem>

export function getProvince() {
  return request<LocationResponse>({
    url: '/nosecure/common/province',
    method: 'get'
  })
}

export function getCity(code: string) {
  return request<LocationResponse>({
    url: `/nosecure/common/city/${code}`,
    method: 'get'
  })
}

export function getArea(code: string) {
  return request<LocationResponse>({
    url: `/nosecure/common/area/${code}`,
    method: 'get'
  })
}

// 获取网站配置参数
export interface WebsiteConfig {
  f2wz: string
  f1wz: string
  swyf: string
  kfqq: string
  ztdz: '{"receiver": "自提","phone": "13723490224","completelAddress": "广东省深圳市宝安区 李先生"}'
  kfdh: string
  wxgzh: string
  gsdz: string
  sbsj: string
  snyf: string
  f5wz: string
  f4wz: string
  f3wz: string
}
export function getWebsiteConfig() {
  return request<WebsiteConfig>({
    url: `nosecure/common/param`,
    method: 'get'
  })
}
// 上传图片
export function uploadFile(data) {
  return request<string>(
    {
      url: `/util/upload`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
