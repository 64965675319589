import { StatusItem } from './../types/order'
import request from '@/utils/request'
import { InvoiceForm } from './buyer'
export interface UpdateCartParams {
  qty: number
  stockId: number
}
export interface ShopCartItem {
  brand: string
  categoryName: string
  id: number
  imgAddress: string
  inventory: number
  model: string
  pack: string
  prices: { moq: number; price: number }[]
  qty: number
  stockId: number
  totalAmount: number
  unitPrice: number
  checked: boolean
}

// 获取购物车列表
export function getShopCartList() {
  return request<ShopCartItem[]>(
    {
      url: `/shop/cart/list`,
      method: 'get'
    }
    // ,
    // {
    //   loading: true
    // }
  )
}

export interface AddToCartProps {
  qty: number
  stockId: string
}
// 加入购物车
export function addToShopCart(data: UpdateCartParams) {
  return request(
    {
      url: '/shop/cart/add',
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

// 更新购物车

export function updateToShopCart(data: UpdateCartParams) {
  return request(
    {
      url: '/shop/cart/update',
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

// 获取购物车数量
export interface CartCountParams {
  memId: number
}
export function getShopCartCount(memId: number) {
  return request({
    url: `/nosecure/common/cartCount?memId=${memId}`,
    method: 'get'
  })
}
// 购物车删除
export function removeShopCart(data: number[]) {
  return request(
    {
      url: `/shop/cart/remove`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

//生成订单
export interface CreateOrderParams {
  address: {
    phone: string
    receiver: string
    completelAddress: string
  }
  vatInvocie: InvoiceForm
  cartIds: number[]
  deliveryType: string
  invoceType: string
  custRemark: string
}

export function createOrder(data: CreateOrderParams) {
  return request(
    {
      url: `/shop/order/create`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

// 查看支付信息
export interface OrderPayInfo {
  sno?: string
  createdTime?: string
  totalAmount?: number
  status?: StatusItem
  payOfflineTradeNo?: number | null
  payOfflineImgAddress?: string | null
}
export function getPayInfo(sno: string) {
  return request<OrderPayInfo>(
    {
      url: `/pay/${sno}`,
      method: 'get'
    },
    {
      loading: true
    }
  )
}
// 保存线下支付
export function saveOffline(sno, data) {
  return request<OrderPayInfo>(
    {
      url: `/pay/offline/save/${sno}`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

//

// 保存阿里支付
export function createAliPay(sno: string) {
  return request<string>(
    {
      url: `/nosecure/pay/ali/h5/create/${sno}`,
      method: 'get'
    },
    {
      loading: true
    }
  )
}

// 保存线下支付
export function createWxPay(
  sno: string,
  data: {
    tradeType: string
  }
) {
  return request<{
    appid: string
    codeURL: string
    errCode: string
    errCodeDes: string
    mchId: string
    mwebUrl: string
    nonceStr: string
    prepayId: string
    resultCode: string
    returnCode: string
    returnMsg: string
    sign: string
    subAppId: string
    subMchId: string
    tradeType: string
    xmlString: string
  }>(
    {
      url: `/pay/weixin/create/${sno}`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

export function createWechatPay(
  sno: string,
  data: {
    openId: string
  }
) {
  return request<{
    appId: string
    timeStamp: string
    nonceStr: string
    package: string
    signType: string
    paySign: string
  }>(
    {
      url: `/pay/weixin/prepare/${sno}`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
// 获取订单价格
export function getOrderAmount(sno: string) {
  return request<{
    totalAmount: number
    status: StatusItem
  }>(
    {
      url: `/pay/${sno}`,
      method: 'get'
    },
    {
      loading: true
    }
  )
}
