import request from '@/utils/request'
import { StatusItem, ModelsItem } from '@/types/order'

// 获取操作记录
export interface OperateLogParams {
  pageNum: number
  pageSize: number
}
export interface OperateLogItem {
  description: string
  content: string
  createdTime: string
}
export function getSellerOperateLog(data: OperateLogParams) {
  return request<OperateLogItem[]>({
    url: `seller/stock/operateLog`,
    method: 'post',
    data
  })
}

// 获取库存列表ts
export type InventoryStatus = 'SHELF' | 'OBTAINED'
export interface SellerInventoryListProps {
  pageNum: number
  pageSize: number
  model: string
  status: InventoryStatus
  title: string
  updateTimeFrom: string
  updateTimeTo: string
}

export interface SellerInventoryListItem {
  batch: string
  brand: string
  categoryId: string
  categoryName: string
  createdTime: string
  id: 4840
  imgAddress: string
  inventory: 977
  model: string
  pack: string
  pdfAddress: string
  prices: {
    moq: number
    price: number
  }[]
  sort: number
  status: StatusItem
  title: string
  updatedTime: string
  reduceType: StatusItem
}

export type SellerInventoryListResponse = {
  records: SellerInventoryListItem[]
  total: number
  [prop: string]: any
}

// 获取库存列表
export function getSellerInventoryList(data: SellerInventoryListProps) {
  return request<SellerInventoryListResponse>({
    url: `/seller/stock/list`,
    method: 'post',
    data
  })
}

export interface ReceiveInfoForm {
  depositBankAccount: string
  depositBankName: string
  depositContact: string
  depositBankAddress: string
}
// 获取收款设置信息
export function getReceiveInfo() {
  return request<ReceiveInfoForm>(
    {
      url: `/seller/receipt/get`,
      method: 'post'
    },
    {
      loading: true
    }
  )
}

// 更新收款设置信息
export function updateReceiveInfoForm(data: ReceiveInfoForm) {
  return request(
    {
      url: `seller/receipt/save`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
// 获取订单列表
export interface SellerOrderListProps {
  pageNum: number
  pageSize: number
  mno?: string
  model?: string
  createdStartTime?: string
  createdEndTime?: string
}
export interface SellerOrderListItem {
  id: number
  createdTime: string
  payType: StatusItem
  totalAmount: number
  logisticsFee: number
  status: StatusItem
  modelDetails: ModelsItem[]
  models: ModelsItem[]
  mno: string
  pumpingAmount: number
  receivableAmount: number
  creditBill: string
}

export type SellerOrderListResponse = {
  records: SellerOrderListItem[]
  total: number
  [prop: string]: any
}
// 获取订单列表
export function getSellerOrderList(type: string, data: SellerOrderListProps) {
  return request<SellerOrderListResponse>({
    url: `/seller/ord/list/${type}`,
    method: 'post',
    data
  })
}
// 根据订单号获取详情
export function getSellerOrderDetail(sno: string) {
  return request<SellerOrderListItem>(
    {
      url: `/seller/ord/get/detail/${sno}`,
      method: 'get'
    },
    {
      loading: true
    }
  )
}
//批量上传
export function sellerBatchUpload(data) {
  return request<string>(
    {
      url: `/seller/stock/batchUpload`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
// 逐条上传
export function sellerFormUpload(data) {
  return request(
    {
      url: `/seller/stock/upload`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
// 获取库存详情
export function getSellerInventoryInfo(id: string) {
  return request<SellerInventoryListItem>({
    url: `/seller/stock/${id}`,
    method: 'get'
  })
}
// 更新库存详情
export function updateSellerInventoryInfo(id: string, data) {
  return request(
    {
      url: `seller/stock/update/${id}`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
// 上架
export function sellerOnline(data: number[]) {
  return request(
    {
      url: `/seller/stock/upperShelf`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

// 下架
export function sellerOffline(data: number[]) {
  return request(
    {
      url: `/seller/stock/lowerShelf`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
// 删除
export function sellerDelete(data: number[]) {
  return request(
    {
      url: `/seller/stock/remove`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

// 确认发货
export interface ConfirmShipForm {
  shipLogisticsCompany: string
  shipLogisticsNo: string
}
export function confirmShip(mno: string, data: ConfirmShipForm) {
  return request(
    {
      url: `/seller/ord/confirmShip/${mno}`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

// 获取卖家中心每个状态的订单数量
export function getSellerStatusCount() {
  return request(
    {
      url: `/seller/ord/count`,
      method: 'get'
    },
    {
      loading: true
    }
  )
}
