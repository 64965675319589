<template>
  <div class="tabbar">
    <van-tabbar
      fixed
      placeholder
      border
      v-model="active"
      active-color="#119878"
      inactive-color="#222222"
      @change="onChange"
    >
      <van-tabbar-item
        replace
        v-for="item in tabList"
        :key="item.label"
        :to="item.route"
        :name="item.route"
      >
        {{ item.label }}
        <template v-slot:icon>
          <img
            :src="
              require(`@/assets/home/${item.img}${
                active === item.route ? '_active' : ''
              }.png`)
            "
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const active = ref('/home')
const tabList = ref([
  { img: 'home', label: '首页', route: '/home' },
  { img: 'category', label: '分类', route: '/category' },
  { img: 'car', label: '购物车', route: '/cart/cartList' },
  { img: 'my', label: '我的', route: '/mine' }
])
watch(
  () => router.currentRoute.value,
  (newVal, oldVal) => {
    if (newVal.path === '/') {
      active.value = '/home'
    } else {
      active.value = newVal.path
    }
  },
  {
    immediate: true
  }
)
const onChange = index => {
  router.push(`/${index}`)
}
</script>

<style lang="scss">
.tabbar {
  .van-tabbar__placeholder {
    height: 98px !important;
    .van-tabbar {
      height: auto;
      .van-tabbar-item {
        height: 98px;
      }
      .van-tabbar-item__icon img {
        width: 52px;
        height: 52px;
      }
      .van-tabbar--fixed {
        height: auto;
      }
      .van-tabbar-item__text {
        font-size: 20px;
      }
    }
  }
  .van-tabbar-item--active {
    color: #1dc38e !important;
  }
}
</style>
