import request from '@/utils/request'

// 获取商品目录
export type CategoryItem = {
  id: number
  count: number
  name: string
  text?: string
  childrens: []
}
export type CategoryResponse = Array<CategoryItem>
export function geCategory() {
  return request<CategoryResponse>({
    url: '/nosecure/layout/category',
    method: 'get'
  })
}

// 商品专区
export type ProductCatalogRequest = {
  pageNum: number
  pageSize: number
  selectConditions: string
}
export type ProductCatalogListResponse = Array<{
  id: number
  model: string
}>
export type ProductCatalogResponse = {
  records: ProductCatalogListResponse
  [prop: string]: any
}

export function getProductCatalog(data: ProductCatalogRequest) {
  return request<ProductCatalogResponse>({
    url: 'nosecure/layout/productCatalog',
    method: 'post',
    data
  })
}
export type ProductDetailResponse = {
  id: number
  stockId: number
  brand: string
  categoryName: string
  deliveryDay: string
  imgAddress: string
  inventory: number
  model: string
  mpq: number
  pack: string
  params: Array<{ title: string; value: string }>
  parentCategoryName: string
  pdfAddress: string
  price: number
  prices: Array<{ moq: number; price: number }>
  remark: string
  title: string
}

export function getProductDetailInfo(id: string) {
  return request<ProductDetailResponse>(
    {
      url: 'nosecure/prod/detail/' + id,
      method: 'post'
    },
    {
      loading: true
    }
  )
}

export type ProductBySearchResponse = {
  stocksPage: {
    current: number
    pages: number
    records: ProductDetailResponse[]
    searchCount: boolean
    size: number
    total: number
  }
  packs: string[]
  categoryNames: []
  brands: string[]
}
export type SearchRequest = {
  hasStockFlag?: boolean
  keyword?: string
  pageNum: number
  pageSize?: number
  priceEnd?: string
  priceStart?: string
  sortType?: string
  categoryNames?: string[]
  packs?: string[]
  brands?: string[]
}
export function getProductBySearch(data: SearchRequest) {
  return request<ProductBySearchResponse>({
    url: 'nosecure/prod/search',
    method: 'post',
    data
  })
}
