import { createStore } from 'vuex'
import { user, UserState } from './modules/user'
import { home, HomeState } from './modules/home'
import { buyer, BuyerState } from './modules/buyer'
import { common } from './modules/common'
import { cart, CartState } from './modules/cart'

export interface GlobalProp {
  user: UserState
  cart: CartState
  buyer: BuyerState
  home: HomeState
}

export default createStore({
  modules: { user, home, buyer, common, cart }
})
