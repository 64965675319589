import { AxiosRequestConfig } from 'axios'
import { Toast, Dialog } from 'vant'
import store from '@/store'
import service from './axios'
import { DialogToLogin } from '@/utils/common'
interface BaseResponse<T> {
  code: number
  status: number
  data: T
  msg?: string
}
interface OtherRequestComfig {
  loading?: boolean
}

const request = <T>(
  config: AxiosRequestConfig,
  otherConfig = { loading: false }
): Promise<BaseResponse<T>> => {
  const toast = null
  const { loading } = otherConfig
  if (loading) {
    Toast.loading({
      duration: 0,
      loadingType: 'spinner',
      forbidClick: true,
      message: ''
    })
  }
  return new Promise((resolve, reject) => {
    service.request<BaseResponse<T>>(config).then(
      res => {
        Toast.clear()
        switch (res.data?.status) {
          case 401:
            Dialog.alert({
              title: '提示',
              message: '您无权访问该页面'
            }).then(() => {
              location.href = `${location.protocol}${location.host}/#/`
            })
            break
          case 203:
            DialogToLogin()
            break
          case 200:
            resolve(res.data)
            break
          default:
            if (res.config.url && res.config.url.indexOf('ali/create/') > -1) {
              resolve(res.data)
            } else {
              Toast(res.data.msg || '网络异常')
              reject(res.data)
            }
            break
        }
      },
      err => {
        Toast.clear()
        reject(err)
      }
    )
  })
}

export default request
