import { Module } from 'vuex'
import { GlobalProp } from '@/store'
import {
  ShopCartItem,
  getShopCartList,
  getShopCartCount,
  removeShopCart,
  updateToShopCart,
  addToShopCart,
  OrderPayInfo,
  getPayInfo
} from '@/api'

export interface CartState {
  shopCartList: ShopCartItem[]
  cartCount: number
  cartCheckedIdList: number[]
  orderPayInfo: OrderPayInfo
  hasQueryCartCount: boolean
}

export const cart: Module<CartState, GlobalProp> = {
  namespaced: true,
  state: {
    shopCartList: [], //购物车列表
    cartCount: 0,
    cartCheckedIdList: [], //购物车选中的id list
    orderPayInfo: {}, //订单支付信息
    hasQueryCartCount: false //是否请求购物车数量
  },
  getters: {},
  mutations: {
    // 设置购物车列表
    set_shopCart_list: (state, list) => {
      list.forEach(item => {
        if (state.cartCheckedIdList.includes(item.stockId)) {
          item.checked = true
        }
      })
      state.shopCartList = list
    },
    // 设置购物车数量
    set_cart_num: (state, num) => {
      state.cartCount = num
      state.hasQueryCartCount = true
    },
    // 设置购物车中选中的商品ID_List
    set_cart_checked_id_list: (state, list) => {
      state.cartCheckedIdList = list
    },

    remove_add_cart_checked_list: (state, id) => {
      const index = state.cartCheckedIdList.indexOf(id)
      if (index > -1) {
        state.cartCheckedIdList.splice(index, 1)
      } else {
        state.cartCheckedIdList.push(id)
      }
    },
    set_order_pay_info: (state, info) => {
      state.orderPayInfo = info
    }
  },
  actions: {
    // 获取购物车列表
    async getShopCartList({ commit, state }) {
      const res = await getShopCartList()
      if (
        !(
          state.shopCartList.length === res.data.length &&
          state.shopCartList.length === 0
        )
      ) {
        commit('set_shopCart_list', res.data)
      }
      return res.data
    },
    //加入购物车
    async addShopCart({ commit, dispatch }, params) {
      const res = await addToShopCart(params)
      dispatch('getShopCartList')
      return res.data
    },
    // 更新购物车
    async updateShopCart({ commit, dispatch }, params) {
      const res = await updateToShopCart(params)
      dispatch('getShopCartList')
      return res.data
    },
    // 删除购物车
    async removeShopCart({ commit, rootState, dispatch }, params) {
      const { cartCheckedIdList } = rootState.cart
      const res = await removeShopCart(cartCheckedIdList)
      // 更新购物车
      dispatch('getShopCartList')
      return res.data
    },

    // 获取购物车数量
    async getShopCartCount({ commit, rootState }) {
      const { userInfo, token } = rootState.user
      if (!token) return
      const res = await getShopCartCount(userInfo.id)
      commit('set_cart_num', res.data)
      return res.data
    },
    async getOrderPayInfo({ commit, rootState }, sno) {
      const res = await getPayInfo(sno)
      res.data.createdTime = res.data.createdTime?.split('T').join(' ')
      commit('set_order_pay_info', res.data)
      return res.data
    }
  }
}
