import { ref, computed } from 'vue'
import { addToShopCart, UpdateCartParams, getShopCartCount } from '@/api'
import { Dialog, Toast } from 'vant'
import { useStore } from 'vuex'
const useCart = () => {
  const store = useStore()
  const cartCount = computed(() => {
    return store.state.cart.cartCount
  })
  const hasQueryCartCount = computed(() => {
    return store.state.cart.hasQueryCartCount
  })
  const addToCart = async (params: UpdateCartParams) => {
    // const res = await addToShopCart(params)
    await store.dispatch('cart/addShopCart', params)
    getCartCount()
  }
  const getCartCount = async () => {
    if (!hasQueryCartCount.value.vlaue) {
      store.dispatch('cart/getShopCartCount')
    }
  }
  // 修改数量
  const changeNumer = (item, qty) => {
    store.dispatch('cart/updateShopCart', {
      qty: qty,
      stockId: item.stockId
    })
  }
  return { cartCount, addToCart, getCartCount, changeNumer }
}

export default useCart
