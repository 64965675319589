export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/loginIn.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue'),
    meta: {
      title: '注册账号'
    }
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../views/login/register.vue'),
    meta: {
      title: '找回密码'
    }
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mine/index.vue'),
    meta: {
      title: '我的',
      showTab: true,
      hideCart: true
    }
  }
]
