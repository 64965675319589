import axios, { AxiosInstance } from 'axios'
// eslint-disable-next-line
import { getStorage, STORAGE_KEY } from '@/utils/auth'
import store from '@/store'

// eslint-disable-next-line
const config = require('../../config')
const { baseURL } = config[process.env.NODE_ENV]

// 创建axios实例
const service: AxiosInstance = axios.create({
  baseURL,
  timeout: 20000
  // withCredentials: true
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = `${getStorage(STORAGE_KEY.TOKEN)}`
    // const token = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjMsImV4cCI6MTY3MDkzODI2NywiYWNjb3VudCI6IjEzNjIzMDEyMzM2In0.aa9bxVvLom_gQmlChJsr8uXfqm2JWvxU2dj5Hv-MrmM`
    if (token) {
      config.headers.Authorization = token
    }
    // 上传接口
    if (config.url?.includes('pload')) {
      config.headers['Content-Type'] = 'application/json'
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.status !== 200) {
      return Promise.reject(response)
    } else {
      return response
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
