import request from '@/utils/request'

// 获取客户地址
export type AddressItem = {
  completelAddress: string
  defaultFlag: boolean
  id: number
  phone: string
  receiver: string
}
export type AddressListResponse = Array<AddressItem>

export function getAddressList() {
  return request<AddressListResponse>(
    {
      url: '/buyer/address/list',
      method: 'get'
    },
    {
      loading: true
    }
  )
}

export interface AddressInfo {
  areaCode: string
  areaName: string
  cityCode: string
  cityName: string
  detailAddress: string
  phone: string
  provinceCode: string
  provinceName: string
  receiver: string
  address: string
  defaultFlag?: boolean
}
// 获取单条地址
export function getAddressItem(id) {
  return request<AddressInfo>(
    {
      url: '/buyer/address/get/' + id,
      method: 'get'
    },
    {
      loading: true
    }
  )
}

export interface addressInfoProp {
  receiver: string
  phone: string
  provinceCode: string
  provinceName: string
  cityCode: string
  cityName: string
  areaCode: string
  areaName: string
  detailAddress: string
  id?: string
}

//新增地址
export function addAddress(data: addressInfoProp) {
  return request(
    {
      url: '/buyer/address/add',
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
//编辑地址
export function updateAddress(id, data: addressInfoProp) {
  return request(
    {
      url: '/buyer/address/update/' + id,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

// 设为默认地址
export function setAddressDefault(id: string) {
  return request(
    {
      url: `/buyer/address/default/${id}`,
      method: 'post'
    },
    {
      loading: true
    }
  )
}
// 删除地址
export function delAddress(id: string) {
  return request(
    {
      url: `/buyer/address/remove/${id}`,
      method: 'post'
    },
    {
      loading: true
    }
  )
}
