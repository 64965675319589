export default [
  {
    path: '/seller',
    redirect: '/seller/orderList',
    meta: {
      needAuth: true,
      title: '订单列表'
    },
    children: [
      {
        meta: {
          title: '我的订单',
          needAuth: true,
          showCart: true
        },
        name: 'mySellerOrder',
        path: '/seller/orderList',
        component: () => import('../views/seller/orderList.vue')
      },
      {
        meta: {
          title: '订单详情',
          needAuth: true,
          showCart: true
        },
        name: 'mySellerOrderDetail',
        path: '/seller/orderDetail',
        component: () => import('../views/seller/orderDetail.vue')
      },
      {
        meta: {
          title: '上传库存',
          needAuth: true
        },
        name: 'sellerUpload',
        path: '/seller/upload',
        component: () => import('../views/seller/upload.vue')
      },
      {
        meta: {
          title: '库存列表',
          needAuth: true
        },
        name: 'inventoryList',
        path: '/seller/inventoryList',
        component: () => import('../views/seller/inventoryList.vue')
      },
      {
        meta: {
          title: '收款设置',
          needAuth: true
        },
        name: 'receiveSet',
        path: '/seller/receiveSet',
        component: () => import('../views/seller/receiveSet.vue')
      },
      {
        meta: {
          title: '操作记录',
          needAuth: true
        },
        name: 'sellerRecords',
        path: '/seller/records',
        component: () => import('../views/seller/records.vue')
      }
    ]
  }
]
