import { useRouter, useRoute } from 'vue-router'

type queryType = {
  [prop: string]: string
}
const useRouter_Route = () => {
  const router = useRouter()
  const route = useRoute()

  // 跳转
  const toNextRoute = (path: string, query?: queryType) => {
    if (path === '-1') {
      router.go(-1)
    }
    router.push({
      path,
      query
    })
  }
  // 获取路由参数
  const getRouteParams = (key?: string, isParams?: boolean) => {
    if (key) {
      if (isParams) {
        return route.params[key] || ''
      } else {
        return route.query[key] || ''
      }
    }
    return route.query
  }
  return { toNextRoute, getRouteParams }
}

export default useRouter_Route
