import { Module } from 'vuex'
import { GlobalProp } from '@/store'
import { loginIn, GetInfoResponse, register } from '@/api'

import {
  STORAGE_KEY,
  getStorage,
  setStorage,
  removeStorage
} from '@/utils/auth'

export interface UserState {
  token: string | undefined
  userInfo: GetInfoResponse
  userCenter: 'buyer' | 'seller'
  wxUser: any
  openId: any
}

const userInfo = getStorage(STORAGE_KEY.USER)
export const user: Module<UserState, GlobalProp> = {
  namespaced: true,
  state: {
    token: getStorage(STORAGE_KEY.TOKEN),
    userInfo: JSON.parse(userInfo || '{}') || {},
    userCenter: 'buyer',
    wxUser: null,
    openId: ''
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setStorage(STORAGE_KEY.TOKEN, token)
    },
    SING_OUT(state, token) {
      state.token = token
    },
    // 存储用户信息
    SET_USER_INFO(state, info) {
      state.userInfo = info
      if (info) {
        localStorage.setItem(STORAGE_KEY.USER, JSON.stringify(info))
      } else {
        removeStorage(STORAGE_KEY.USER)
      }
    },
    // 清除用户信息
    clear_user_info(state) {
      // state.userInfo = {}
      localStorage.clear()
    },
    // 设置用户中心类型
    set_userCenter(state, type) {
      state.userCenter = type
    },
    SET_WX_USER(state, user) {
      state.wxUser = user
      localStorage.setItem('WEIDIANSI_USER', JSON.stringify(user))
    },
    SET_OPENID(state, openId) {
      state.openId = openId
      localStorage.setItem('WEIDIANSI_OPENID', openId)
    }
  },
  actions: {
    // 登录
    loginIn({ commit }, payload) {
      return new Promise((resolve, reject) => {
        loginIn(payload)
          .then(res => {
            const { token } = res.data
            commit('SET_TOKEN', token)
            commit('SET_USER_INFO', res.data)
            setStorage(STORAGE_KEY.TOKEN, token)
            resolve(token)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    register({ commit }, payload) {
      return new Promise((resolve, reject) => {
        register(payload)
          .then(res => {
            const { token } = res.data
            commit('SET_TOKEN', token)
            commit('SET_USER_INFO', res.data)
            setStorage(STORAGE_KEY.TOKEN, token)
            resolve(token)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 登出
    signOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO', null)
        removeStorage(STORAGE_KEY.TOKEN)

        resolve(undefined)
      })
    }
    // // 获取基本用户信息
    // getInfo({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo()
    //       .then(res => {
    //         const data = res.data
    //         commit('SET_USER_INFO', data)
    //         resolve(data)
    //       })
    //       .catch(err => {
    //         reject(err)
    //       })
    //   })
    // }
  },
  getters: {
    getUid(state) {
      return state.userInfo.id
    },
    getToken(state) {
      return state.token
    }
  }
}
