import { Module } from 'vuex'
import { GlobalProp } from '@/store'

import {
  BannerResponse,
  ProductResponse,
  CategoryResponse,
  ProductCatalogListResponse,
  geCategory
} from '@/api'

export interface HomeState {
  ads: BannerResponse
  productInfo: ProductResponse
  categoryList: CategoryResponse
  alphabetProductInfo: {
    [prop: string]: ProductCatalogListResponse
  }
}

export const home: Module<HomeState, GlobalProp> = {
  namespaced: true,
  state: {
    ads: {}, //banner
    productInfo: {}, //首页商品
    categoryList: [], //商品目录
    alphabetProductInfo: {} //商品专区的字母对应的商品list
  },
  getters: {
    ads(state) {
      return state.ads
    }
  },
  mutations: {
    SET_ADS: (state, ads) => {
      state.ads = ads
    },
    set_productInfo: (state, productInfo) => {
      state.productInfo = productInfo
    },
    set_categoryList: (state, list) => {
      state.categoryList = list
    },
    set_alphabetProductInfo: (state, { key, list }) => {
      state.alphabetProductInfo[key] = list
    }
  },
  actions: {
    async geCategory({ commit, rootState }) {
      const { categoryList } = rootState.home
      if (categoryList.length) return
      const res = await geCategory()
      const arr = JSON.parse(JSON.stringify(res.data))
      const initArr = list => {
        list.forEach(child => {
          child.text = child.name
          if (!child.childrens.length) {
            delete child.childrens
          } else {
            initArr(child.childrens)
          }
        })
      }
      initArr(arr)

      commit('set_categoryList', arr)
      return arr
    }
  }
}
