import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import CartRoute from './cart'
import UserRoute from './user'
import BuyerRoute from './buyer'
import SellerRoute from './seller'
const routes: Array<RouteRecordRaw> = [
  ...CartRoute,
  ...UserRoute,
  ...BuyerRoute,
  ...SellerRoute,
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/index.vue'),
    meta: {
      title: '首页',
      showTab: true,
      keepAlive: true,
      hideCart: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '/'
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('../views/category/index.vue'),
    meta: {
      title: '商品目录',
      showTab: true,
      hideCart: true
    }
  },
  {
    path: '/productZoom',
    name: 'productZoom',
    component: () => import('../views/product/zoom.vue'),
    meta: {
      title: '商品专区'
    }
  },

  {
    path: '/product/:id',
    name: 'productDetail',
    component: () => import('@/views/product/detail.vue'),
    meta: {
      title: '商品详情'
    }
  },

  {
    path: '/searchInput',
    name: 'searchInput',
    component: () => import('@/views/search/index.vue'),
    meta: {
      title: '搜索',
      showCart: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/result.vue'),
    meta: {
      title: '搜索结果',
      showCart: true
    }
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('@/views/join/index.vue'),
    meta: {
      title: '免费入住'
    }
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import('../views/company/introduce.vue'),
    meta: {
      title: '公司介绍'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
