import request from '@/utils/request'

export type ImgResponse = { imgAddress: string; urlAddress: string }

// 获取banner
export type BannerResponse = {
  [key: string]: ImgResponse[]
}
export function getBanner() {
  return request<BannerResponse>({
    url: '/nosecure/home/ad',
    method: 'get'
  })
}

// 获取首页商品
export type ProductItem = {
  brand: string
  imgAddress: string
  jumpType: { value: number; desc: string; enumName: string }
  model: string
  price: number
  stockId: number
}
export interface ProductResponse {
  [prop: string]: ProductItem[]
}
export function getProduct() {
  return request<ProductResponse>({
    url: '/nosecure/home/product',
    method: 'get'
  })
}
export type JoinUsForm = {
  company: string
  contact: string
  contactPhone: string
  depositBankName: string
  depositBankAccount: string
  depositContact: string
  depositBankAddress: string
}

export function joinUs(data: JoinUsForm) {
  return request(
    {
      url: '/mem/apply/entering',
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
