import { App } from 'vue'
import {
  Button,
  List,
  PullRefresh,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  Image,
  Form,
  Field,
  NavBar,
  Icon,
  Cell,
  CellGroup,
  Search,
  Checkbox,
  Dialog,
  TreeSelect,
  IndexBar,
  IndexAnchor,
  Overlay,
  Loading,
  Badge,
  AddressList,
  Switch,
  Popup,
  Cascader,
  Calendar,
  Empty,
  Stepper,
  Uploader,
  RadioGroup,
  Radio,
  Picker,
  CountDown,
  ActionSheet,
  DropdownMenu,
  DropdownItem
} from 'vant'

// https://vant-contrib.gitee.io/vant/v3/#/zh-CN
const plugins = [
  Button,
  List,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  Image,
  PullRefresh,
  Form,
  Field,
  NavBar,
  Icon,
  Cell,
  CellGroup,
  Search,
  Checkbox,
  Dialog,
  TreeSelect,
  IndexBar,
  IndexAnchor,
  Overlay,
  Loading,
  Badge,
  AddressList,
  Switch,
  Popup,
  Cascader,
  Calendar,
  Empty,
  Stepper,
  Uploader,
  RadioGroup,
  Radio,
  Picker,
  CountDown,
  ActionSheet,
  DropdownMenu,
  DropdownItem
]

export default {
  install(vm: App) {
    plugins.forEach(item => {
      vm.component(item.name, item)
    })
  }
}
