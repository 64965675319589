export default [
  {
    path: '/buyer',
    redirect: '/buyer/orderList',
    meta: {
      needAuth: true,
      title: '订单列表'
    },
    children: [
      {
        meta: {
          title: '我的订单',
          needAuth: true,
          showCart: true
        },
        name: 'myBuyerOrder',
        path: '/buyer/orderList',
        component: () => import('../views/buyer/orderList.vue')
      },
      {
        meta: {
          title: '订单详情',
          needAuth: true,
          showCart: true
        },
        name: 'myBuyerOrderDetail',
        path: '/buyer/orderDetail',
        component: () => import('../views/buyer/orderDetail.vue')
      },
      {
        meta: {
          title: '发票设置',
          needAuth: true
        },
        name: 'invoice',
        path: '/buyer/invoice',
        component: () => import('../views/buyer/invoice.vue')
      },
      {
        meta: {
          title: '基本信息',
          needAuth: true
        },
        name: 'basic',
        path: '/buyer/basic',
        component: () => import('../views/buyer/basic.vue')
      },
      {
        path: '/addressList',
        name: 'addressList',
        component: () => import('../views/buyer/address/list.vue'),
        meta: {
          title: '我的地址',
          needAuth: true
        }
      },
      {
        path: '/editAddress',
        name: 'editAddress',
        component: () => import('../views/buyer/address/edit.vue'),
        meta: {
          title: '编辑地址',
          needAuth: true
        }
      },
      {
        path: '/buyer/resetPassword',
        name: 'resetPassword',
        component: () => import('../views/buyer/resetPassword.vue'),
        meta: {
          title: '密码修改',
          needAuth: true
        }
      }
    ]
  }
]
