export default [
  {
    path: '/cart',
    redirect: '/cart/cartList',
    meta: {
      needAuth: true,
      title: '购物车'
    },
    children: [
      {
        meta: {
          title: '购物车',
          showTab: true,
          keepAlive: true,
          needAuth: true,
          hideCart: true
        },
        name: 'cartList',
        path: '/cart/cartList',
        component: () => import('../views/cart/cartList.vue')
      },
      {
        meta: {
          title: '订单结算',
          keepAlive: true,
          needAuth: true
        },
        name: 'confirmOrder',
        path: '/cart/confirmOrder',
        component: () => import('../views/cart/confirmOrder.vue')
      },
      {
        meta: {
          title: '支付订单',
          needAuth: true
        },
        name: 'selectPayType',
        path: '/cart/selectPayType',
        component: () => import('../views/cart/selectPayType.vue')
      },
      {
        meta: {
          title: '支付订单',
          needAuth: true
        },
        name: 'payResult',
        path: '/cart/payResult',
        component: () => import('../views/cart/payResult.vue')
      },
      {
        meta: {
          title: '支付订单',
          needAuth: true
        },
        name: 'OfflinePay',
        path: '/cart/OfflinePay',
        component: () => import('../views/cart/OfflinePay.vue')
      }
    ]
  }
]
