<template lang="">
  <div class="h-full flex flex-col">
    <div :class="`flex-1 w-full container`">
      <div class="w-full h-full">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component
              :is="Component"
              v-if="$route.meta.keepAlive"
              :key="$route.name"
            />
          </keep-alive>
          <component
            :is="Component"
            v-if="!$route.meta.keepAlive"
            :key="$route.name"
          />
        </router-view>
      </div>
    </div>
    <Tabbar class="tab" v-if="$route.meta.showTab" />
  </div>
  <CartIcon v-if="token && $route.meta.showCart"></CartIcon>
  <HomeIcon v-if="!$route.meta.hideCart"></HomeIcon>
</template>

<script setup>
import { computed } from 'vue'
import Tabbar from '../Tabbar'
import CartIcon from '@/components/CartIcon/index.vue'
import HomeIcon from '@/components/HomeIcon/index.vue'
import { useStore } from 'vuex'

const store = useStore()
const token = computed(() => {
  return store.state.user.token
})
</script>
<style lang="scss" scoped>
.tab {
  height: 98px;
}
.container {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
