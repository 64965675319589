import request from '@/utils/request'
import {
  StatusItem,
  ModelsItem,
  ReceiptItem,
  logisticsItem
} from '@/types/order'
export interface BuyerOrderListProps {
  pageNum: number
  pageSize: number
  sno?: string
  model?: string
  createdStartTime?: string
  createdEndTime?: string
}
export interface BuyerOrderListItem {
  id: number
  createdTime: string
  sno: string
  memName: string
  payType: StatusItem
  totalAmount: number
  logisticsFee: number
  status: StatusItem
  shipLogistics: logisticsItem
  invoiceLogistics: logisticsItem
  models: ModelsItem[]
  payAmount?: number
  custRemark?: string
  modelDetails?: ModelsItem[]
  invoice?: string
  receipt?: ReceiptItem
}

export type BuyerOrderListResponse = {
  records: BuyerOrderListItem[]
  total: number
  [prop: string]: any
}

// 获取订单列表
export function getBuyerOrderList(type: string, data: BuyerOrderListProps) {
  return request<BuyerOrderListResponse>({
    url: `/buyer/ord/list/${type}`,
    method: 'post',
    data
  })
}
// 取消订单
export function cancelBuyerOrder(sno: string) {
  return request(
    {
      url: `/buyer/ord/cancel/${sno}`,
      method: 'post'
    },
    {
      loading: true
    }
  )
}
// 确认收货
export function confirmReceipt(sno: string) {
  return request(
    {
      url: `/buyer/ord/confirmReceipt/${sno}`,
      method: 'post'
    },
    {
      loading: true
    }
  )
}
// 根据订单号获取详情

export function getBuyerOrderDetail(sno: string) {
  return request<BuyerOrderListItem>(
    {
      url: `/buyer/ord/get/detail/${sno}`,
      method: 'get'
    },
    {
      loading: true
    }
  )
}

// 再次购买
export function repurchase(sno: string) {
  return request(
    {
      url: `/buyer/ord/repurchase/${sno}`,
      method: 'post'
    },
    {
      loading: true
    }
  )
}

export interface InvoiceForm {
  companyAddress: string
  companyTelephone: string
  depositBankAccount: string
  depositBankName: string
  invoiceTitle: string
  receiptAddress: string
  receiptContact: string
  receiptContactPhone: string
  tin: string
}

// 获取企业发票信息
export function getCompanyInvoice() {
  return request<InvoiceForm>({
    url: `/buyer/invoice/get/vat`,
    method: 'get'
  })
}

// 更新公司发票
export function updateCompanyInvoice(data: InvoiceForm) {
  return request(
    {
      url: `/buyer/invoice/save/vat`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

export interface BasicInfoForm {
  phone: string
  name: string
  company: string
  qq: string
  email: string
  telephone: string
}
// 获取买家基本信息
export function getBasicInfo() {
  return request<BasicInfoForm>(
    {
      url: `/buyer/info/get`,
      method: 'post'
    },
    {
      loading: true
    }
  )
}
// 获取买家中心每个状态的订单数量
export function getBuyerStatusCount() {
  return request(
    {
      url: `/buyer/ord/count`,
      method: 'get'
    },
    {
      loading: true
    }
  )
}

// 更新买家基本信息
export function updateBasicInfoForm(data: BasicInfoForm) {
  return request(
    {
      url: `buyer/info/save`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

// 修改密码
export interface ResetPwdForm {
  password: string
  oriPassword: string
  repeatPassword: string
}
export function resetPwd(data: ResetPwdForm) {
  return request(
    {
      url: `/buyer/pwd/reset`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
