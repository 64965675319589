import { Module } from 'vuex'
import { GlobalProp } from '@/store'
import { AddressItem, getAddressList } from '@/api'
export interface BuyerState {
  addressList: AddressItem[]
  currentAddress: AddressItem
}

export const buyer: Module<BuyerState, GlobalProp> = {
  namespaced: true,
  state: {
    addressList: [],
    currentAddress: {
      completelAddress: '',
      defaultFlag: false,
      id: 0,
      phone: '',
      receiver: ''
    }
  },
  getters: {},
  mutations: {
    set_address_list: (state, list) => {
      state.addressList = list
    },
    set_current_address: (state, address) => {
      state.currentAddress = address
    }
  },
  actions: {
    //获取地址
    async getAddressList({ commit, rootState }) {
      const { addressList, currentAddress } = rootState.buyer
      // if (addressList.length) return addressList
      const res = await getAddressList()
      commit('set_address_list', res.data)
      const setCurrentAddress = () => {
        let address = res.data.find(item => item.defaultFlag)
        if (!address) {
          if (res.data.length) {
            address = res.data[0]
          } else {
            address = {
              completelAddress: '',
              defaultFlag: false,
              id: 0,
              phone: '',
              receiver: ''
            }
          }
        }

        commit('set_current_address', address)
      }
      if (!currentAddress.id) {
        setCurrentAddress()
      } else {
        const hasAdress = res.data.find(item => item.id === currentAddress.id)
        // 如果之前选择的地址被删除了，重新赋值地址
        if (!hasAdress) {
          setCurrentAddress()
        }
      }
      return res.data
    }
  }
}
