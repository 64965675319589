import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import './icons/index'
import './styles/index.scss'
import 'vant/lib/index.css'
import './utils/auth'
import './utils/permission'
// import './vconsole'
import vant from '@/components/Vant'
// 解决在ios微信浏览器中输入框点击不灵敏问题
import FastClick from 'fastclick'
FastClick.prototype.focus = function (targetElement) {
  let length
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (
    targetElement.setSelectionRange &&
    targetElement.type.indexOf('date') !== 0 &&
    targetElement.type !== 'time' &&
    targetElement.type !== 'month'
  ) {
    length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length) //修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘
  } else {
    targetElement.focus()
  }
}
FastClick.attach(document.body)
// eslint-disable-next-line
createApp(App).use(store).use(router).use(vant).mount('#app')
