import { Module } from 'vuex'
import { GlobalProp } from '@/store'
import { LocationProp, getWebsiteConfig } from '@/api'

export interface CommonState {
  provinceList: LocationProp
  // 配置项目
  websiteConfig: {
    onlineTime: string
    tel: string
    qq: string
    address: string
    // selfAddress: string
    qrcode: string
    inProviceFee: string
    outProviceFee: string
    f1wz: string
    f2wz: string
    f3wz: string
    f4wz: string
    f5wz: string
  }
}

export const common: Module<CommonState, GlobalProp> = {
  namespaced: true,
  state: {
    provinceList: [],
    // 配置项目
    websiteConfig: {
      // 上班时间
      onlineTime: '',
      // 联系电话
      tel: '',
      // 联系qq
      qq: '',
      // 公司地址
      address: '',
      // selfAddress: '',
      // 微信公众号
      qrcode: '',
      // 省内顺非快递
      inProviceFee: '',
      // 省内顺外快递
      outProviceFee: '',
      // 楼层名称
      f1wz: '',
      f2wz: '',
      f3wz: '',
      f4wz: '',
      f5wz: ''
    }
  },
  getters: {},
  mutations: {
    set_province_list: (state, list) => {
      state.provinceList = list
    },
    save_website_config(state, data) {
      state.websiteConfig = {
        qrcode: data.wxgzh,
        address: data.gsdz,
        qq: `http://wpa.qq.com/msgrd?v=1&uin=${data.kfqq}&site=qq&menu=yes`,
        tel: data.kfdh,
        onlineTime: data.sbsj,
        inProviceFee: data.snyf,
        outProviceFee: data.swyf,
        // selfAddress: JSON.parse(data.ztdz),
        f1wz: data.f1wz,
        f2wz: data.f2wz,
        f3wz: data.f3wz,
        f4wz: data.f4wz,
        f5wz: data.f5wz
      }
    }
  },
  actions: {
    // 获取网站配置参数
    async getWebsiteConfig({ commit }) {
      const res = await getWebsiteConfig()
      commit('save_website_config', res.data)
      return res.data
    }
  }
}
