import request from '@/utils/request'
import { StatusItem } from '@/types/order'

export type LoginRequest = {
  phone: string
  password?: string
  captchaCode?: string
  smsCode?: string
}
export type LoginResponse = {
  id: string
  token: string
  name: string
  type: string
}

export function loginOut(data: LoginRequest) {
  return request<LoginResponse>(
    {
      url: `/user/logout`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}
export function sendCode(phone: string) {
  return request({
    url: '/util/smsCode/create?phone=' + phone,
    method: 'get'
  })
}
// 账号密码登录\短信验证码登录
export function loginIn(data: LoginRequest) {
  return request<LoginResponse>(
    {
      url: `/nosecure/mem/${data.smsCode ? 'smsLogin' : 'passwordLogin'}`,
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

export type SigninResponse = string

export type RegisterRequest = {
  phone: string
  smsCode: string
  password: string
  repeatPassword: string
}
export function register(data: RegisterRequest) {
  return request<LoginResponse>(
    {
      url: '/nosecure/mem/register',
      method: 'post',
      data
    },
    {
      loading: true
    }
  )
}

export type ResetPasswordRequest = {
  phone: string
  form: {
    smsCode: string
    password: string
    repeatPassword: string
  }
}

export function resetPassword(data: ResetPasswordRequest) {
  return request({
    url: '/nosecure/mem/resetPassword/' + data.phone,
    method: 'post',
    data: data.form
  })
}

export type GetInfoResponse = {
  id: number
  name: string
  token: string
  type: StatusItem
}

// 获取图形验证码
export function getImageCode() {
  return request<{
    image: string
    key: string
  }>(
    {
      url: `/util/captchaCode/create`,
      method: 'get'
    },
    {
      loading: false
    }
  )
}

// 获取微信授权
export function getWxSignature() {
  return request<{
    appId: string
    timestamp: string
    noncestr: string
    signature: string
  }>(
    {
      url: '/customer/getSignature',
      method: 'get'
    },
    {
      loading: false
    }
  )
}
// 通过code换取iopenid
export function getwxUserinfo(id?: any) {
  return request(
    {
      url: '/nosecure/auth/getWXUserInfo?code=' + id,
      method: 'get'
    },
    {
      loading: false
    }
  )
}

//获取微信Oauth2授权地址

export function getWXOauth2Url(url: string) {
  return request<string>(
    {
      url: '/nosecure/auth/getWXOauth2Url?redirectUri=' + url,
      method: 'get'
    },
    {
      loading: false
    }
  )
}
