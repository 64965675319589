import request from '@/utils/request'
import { getwxUserinfo, getWXOauth2Url } from '@/api/user'
import store from '@/store'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'
// import useRouter_Route from '@/hooks/useRouter_Route'
import { useRouter, useRoute } from 'vue-router'

const showAlert = false

const router = useRouter()
const route = useRoute()
const defaultShareTitle = '深圳维电斯'
const defaultShareDesc = '深圳维电斯'
const defaultShareImg = 'http://service-web.woaday.com/postcard/sharebg.png'
const windowURL = 'https://m.weidiansi.com/'

// 公众号授权
const toAuthUrl = async (url?: string) => {
  // const appId = 'wx73ecf1d410088374'
  // 未授权则去往授权页面
  // const redirectURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${windowURL}
  // &response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
  try {
    // console.log(999)
    const { data } = await getWXOauth2Url(windowURL)
    // alert('跳转授权URL：' + data)
    window.location.replace(data)
  } catch (err) {}
}

export default {
  // 检测是否是微信浏览器中
  isWechat() {
    const ua: any = navigator.userAgent.toLowerCase()
    const type: any = ua.match(/MicroMessenger/i)
    if (
      type === 'micromessenger' ||
      (type?.length && type[0] === 'micromessenger')
    ) {
      return true
    } else {
      return false
    }
  },
  getUrlParam(key: string) {
    const href: any = window.location.href
    const arr1 = href.split('?')
    let arr2
    if (arr1?.length && arr1[1]) {
      arr2 = arr1[1].split('&')
    }
    if (arr2?.length) {
      const str = arr2.filter(i => i.startsWith(key + '='))
      return str?.[0]?.split('=')[1]
    }
    return ''
  },
  async getWxConfigInfo(html?: string) {
    const windowURL = html || window.location.href.split('#')[0]
    showAlert && alert('链接： ' + windowURL)
    // return request
    //   .post(`/postcards/customer/getSignature`, {
    //     url: windowURL
    //   })
    //   .then(res => {
    //     console.log('请求结果', res)
    //     if (res.code === 200) {
    //       return res.data
    //     }
    //     return null
    //   })
    //   .catch(err => {
    //     console.log('错误', err)
    //     return null
    //   })
    return getwxUserinfo(windowURL)
      .then(res => {
        showAlert && alert('3.用户获取：' + JSON.stringify(res))
        if (res.code === 200) {
          return res.data
        } else {
          return null
        }
      })
      .catch(err => {
        return null
      })
  },
  async queryWxUserinfo(code) {
    const localOpenId = code || localStorage.getItem('WEIDIANSI_OPENID')
    return getwxUserinfo(localOpenId)
      .then(res => {
        showAlert && alert('3.用户获取：' + JSON.stringify(res))

        try {
          if (res.status === 200) {
            const data: any = res.data
            localStorage.setItem('WEIDIANSI_OPENID', data)
            store.commit('user/SET_OPENID', data)
            localStorage.setTtem()
          } else {
            Toast.fail('即将重新授权')
            store.commit('user/SET_OPENID', '')
            showAlert && alert('4.即将前去授权')
            // toAuthUrl(windowURL)
          }
        } catch (err) {
          console.log(err)
        }
      })
      .catch(err => {
        console.log(err)
        Toast.fail('获取失败，即将重新授权')
        store.commit('user/SET_OPENID', '')

        // store.commit('SET_USER', '')
        showAlert && alert('5.报错了，即将前去授权' + JSON.stringify(err))
        // toAuthUrl(windowURL)
      })
  },
  async getWxAuthor() {
    // 2.获取code
    const localOpenId = localStorage.getItem('WEIDIANSI_OPENID')

    if (!this.isWechat() || !store.state.user.token || localOpenId) {
      return
    }
    const code = this.getUrlParam('code')

    if (!code) {
      showAlert && alert('1.没有CODE, 先获取openid，没有openid再去授权')
      const localOpenId = localStorage.getItem('WEIDIANSI_OPENID')
      showAlert && alert('2.openid: ' + localOpenId)
      if (localOpenId) {
        // await this.queryWxUserinfo()
      } else {
        showAlert && alert('6.没有openid，去授权')
        return toAuthUrl(windowURL)
      }
    }
    showAlert && alert('7.有code: ' + code)
    return this.queryWxUserinfo(code)
  },
  async initConfig(url, callback) {
    try {
      const configInfo = await this.getWxConfigInfo(url)
      if (!configInfo) return null
      const { appId, nonceStr, signature, timestamp } = configInfo
      wx.config({
        signature,
        appId,
        nonceStr,
        timestamp,
        debug: false,
        jsApiList: [
          'checkJsApi',
          'onMenuShareTimeline',
          'onMenuShareAppMessage'
          // 'updateAppMessageShareData',
          // 'updateTimelineShareData'
          // 'onMenuShareQQ',
          // 'onMenuShareWeibo',
          // 'onMenuShareQZone',
          // 'hideMenuItems',
          // 'showMenuItems',
          // 'hideAllNonBaseMenuItem',
          // 'showAllNonBaseMenuItem',
          // 'translateVoice',
          // 'startRecord',
          // 'stopRecord',
          // 'onVoiceRecordEnd',
          // 'playVoice',
          // 'onVoicePlayEnd',
          // 'pauseVoice',
          // 'stopVoice',
          // 'uploadVoice',
          // 'downloadVoice',
          // 'chooseImage',
          // 'previewImage',
          // 'uploadImage',
          // 'downloadImage',
          // 'getNetworkType',
          // 'openLocation',
          // 'getLocation',
          // 'hideOptionMenu',
          // 'showOptionMenu',
          // 'closeWindow',
          // 'scanQRCode',
          // 'chooseWXPay',
          // 'openProductSpecificView',
          // 'addCard',
          // 'chooseCard',
          // 'openCard'
        ]
      })

      this.setShare({
        url: window.location.origin + '/home'
      })

      if (callback) {
        callback()
      }
    } catch (err) {
      console.log(err)
    }
  },
  setShare(options) {
    const url = window.location.origin + window.location.pathname
    showAlert && alert('分享链接: ' + options.url)
    wx.ready(function () {
      // 分享给朋友
      wx.onMenuShareAppMessage({
        title: options.title || defaultShareTitle,
        desc: options.desc || defaultShareDesc,
        imgUrl: options.imgUrl || defaultShareImg,
        link: options.url || url,
        success: function () {
          console.log('分享给朋友')
          // 用户点击了分享后执行的回调函数
        }
      })
      // 分享到朋友圈
      wx.onMenuShareTimeline({
        title: options.title || defaultShareTitle,
        desc: options.desc || defaultShareDesc,
        imgUrl: options.imgUrl || defaultShareImg,
        link: options.url || url,
        function(res) {
          // 这里是回调函数
          console.log('分享到朋友圈')
        }
      })
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，

        // 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，

        // 对于SPA可以在这里更新签名。

        console.log('好像出错了！！')
      })
    })
  }
}
