import { Toast, Dialog } from 'vant'
import store from '@/store'
// 回到页面顶部
export const backToTop = (distance, speed) => {
  const speedScorll = speed || 500
  const start = window.pageYOffset
  const top = distance ? Number(distance) : 80

  const easeInOutQuad = (t, b, c, d) => {
    if ((t /= d / 2) < 1) return (c / 2) * t * t + b
    return (-c / 2) * (--t * (t - 2) - 1) + b
  }
  let i = 0

  const interval = setInterval(() => {
    const next = Math.floor(easeInOutQuad(10 * i, start, -start, speedScorll))
    if (next <= top) {
      window.scrollTo(0, top)
      clearInterval(interval)
    } else {
      window.scrollTo(0, next)
    }
    i += 1
  }, 16.7)
}
// 新建窗口打开文件
export const windowOpen = (action, method, name?) => {
  const $form = document.createElement('form')
  $form.method = method || 'GET'
  document.body.appendChild($form)
  const $input = document.createElement('input')
  $input.type = 'hidden'
  $form.appendChild($input)
  $input.value = 'filedownload'
  $input.name = name || 'file'
  $form.action = action
  $form.submit()
  document.body.removeChild($form)
}

// 获取订单剩余支付时间
export const getOrderRestPayTime = time => {
  if (time.indexOf('T')) {
    time = time.split('T').join(' ')
  }
  const start = Date.parse(time.replace(/-/g, '/'))
  // 支付时间限制3天
  const duration = 3 * 24 * 60 * 60 * 1000
  const now = Date.now()
  const interval = now - start
  return duration - interval
}
// 去登录弹框
export const DialogToLogin = () => {
  store.dispatch('user/signOut')
  Dialog.alert({
    title: '提示',
    closeOnClickOverlay: true,
    message: '您还未登录或登录已过期，请重新登录'
  }).then(() => {
    location.replace(
      `${location.origin}/#/login?redirect=${encodeURI(location.href)}`
    )
  })
}
