module.exports = {
  mock: {
    baseURL: '/dev-api',
    mockURL: 'https://api.weidiansi.com/mall'
  },
  local: {
    baseURL: '/dev-api',
    mockURL: 'https://api.weidiansi.com/mall'
  },
  development: {
    baseURL: '/dev-api',
    mockURL: 'https://api.weidiansi.com/mall'
  },
  production: {
    baseURL: 'https://api.weidiansi.com/mall',
    mockURL: 'https://api.weidiansi.com/mall'
  }
}
