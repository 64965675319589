export enum STORAGE_KEY {
  TOKEN = 'H5Token',
  USER = 'userInfo'
}
export function getStorage(key) {
  return localStorage.getItem(key) || ''
}

export function setStorage(key, token: string) {
  return localStorage.setItem(key, token)
}

export function removeStorage(key) {
  return localStorage.removeItem(key)
}

// 需要token才能访问
export const needAuthList = ['/buyer', '/seller']
